/* .brand-outlet-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 25px;
}

.brand-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.outletName {
  font-size: 12px;
  color: gray;
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.2s ease;
}
.outletName:hover {
  color: #f8b912;
}

.mobile-outlet-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mobile-outlet-heading {
  margin: 10px;
}
.mobile-outlet-dropdown {
  width: 100%;
  background-color: #e8dfd8;
  height: 60px;
}

.brand-list {
  display: grid;
  grid-template-rows: 50px 100px 10px 10px;
}

.logo {
  grid-area: "logo";
} */

.brand-outlet-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
  gap: 20px;
  margin: 25px;
}

.brand-list {
  display: grid;
  grid-template-rows: auto auto 1fr;
  justify-items: center;
}

.logo {
  grid-row: 1;
}

.brandName {
  grid-row: 2;
}

.country {
  font-size: 12px;
  margin: 5px 0px 10px 0px;
  text-transform: uppercase;
}

.outletName {
  font-size: 12px;
  color: gray;
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.2s ease;
}

.outletName:hover {
  color: #f8b912;
}

.mobile-outlet-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mobile-outlet-heading {
  margin: 10px;
}
.mobile-outlet-dropdown {
  width: 100%;
  background-color: #e8dfd8;
  height: 60px;
}
