.hamburger-menu {
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 100%;
}

.hamburger-menu__button {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50px;
  height: 25px;
  background: none;
  border: none;
  cursor: pointer;
  margin: 25px;
}

.hamburger-menu__nav {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  display: none;
}

.hamburger-menu__nav.open {
  width: 100%;
  display: block;
  transform: translateX(0);
  transition: transform 0.3s ease-in-out;
}

.hamburger-menu__nav.open li {
  display: flex;
  justify-content: center;
  padding: 10px;
  background-color: #e8dfd8;
  text-transform: uppercase;
}

.hamburger-menu__nav.open a {
  text-decoration: none;
  color: black;
}

.hamburger-menu__bar {
  width: 30px;
  height: 2px;
  background-color: black;
  transition: transform 0.3s ease-in-out;
}

.hamburger-menu__button.open .hamburger-menu__bar:first-child {
  transform: translateY(9px) rotate(45deg);
}

.hamburger-menu__button.open .hamburger-menu__bar:nth-child(2) {
  opacity: 0;
}

.hamburger-menu__button.open .hamburger-menu__bar:last-child {
  transform: translateY(-14px) rotate(-45deg);
}
