.frontpage-images {
  position: relative;
}
.frontpage-image {
  object-fit: cover;
  width: 100%;
  height: 600px;
  filter: brightness(80%);
}

.image-container {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.frontpage-image-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 36px;
  color: white;
}

.image-text {
  font-size: 55px;
  font-family: "Libre Caslon Display", serif;
  width: 700px;
  text-align: "center";
}

@media only screen and (max-width: 860px) {
  .image-text {
    font-size: 30px;
    width: 400px;
  }

  .frontpage-image-text {
    font-size: 12px;
  }

  .frontpage-image {
    height: 300px;
  }
}
