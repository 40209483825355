.footer {
  display: flex;
  flex-direction: row-reverse;
  background-color: #e8dfd8;
  padding: 20px;
}

.footer_container {
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
}

.footer_container h3 {
  margin: 0px 40px;
}

.socialbar-wrapper {
  display: flex;
}

.socialbar-container {
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.socialbar-icon-circle {
  border-radius: 30px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 25px;
}

.newsletter-container {
  border-left: 1px solid black;
}

.newsletter-container form {
  margin-left: 40px;
  display: flex;
  flex-direction: row;
}

.newsletter-subscribe-input {
  font-size: 16px;
  background-color: #e8dfd8;
  border-color: #626060;
  border-width: 0 0 1px 0;
  padding: 10px;
  margin: 10px 0;
  min-width: 250px;
}

.newsletter-subscribe-button {
  font-size: 14px;
  margin: 10px 0;
  background-color: #e8dfd8;
  border-bottom: 1px solid black;
  cursor: pointer;
  width: 100%;
  border-color: #626060;
  border-width: 0 0 1px 0;
  white-space: nowrap;
}

@media only screen and (max-width: 1200px) { 
  .footer-logo  {
    display: none;
  }
}

@media only screen and (max-width: 870px) {
  .socialbar-icon-circle {
    margin: 5px;
  }
}
