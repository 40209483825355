.carousel-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 20px;
  height: 150px;
}

.logo-container {
  width: 130px;
  height: 130px;
  margin: 0 20px;
  transition: transform 0.3s, width 0.3s, height 0.3s;
  transform-origin: center center;
}

.logo-container:hover {
  transform: scale(1.2) rotate(20deg);
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.3s;
}

@media only screen and (max-width: 860px) {
  .logo {
    width: 150%;
  }
}
