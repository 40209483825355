.infoboxes {
  opacity: 0;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  animation: fadeIn 2s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.infobox {
  width: 400px;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding: 12px;
  margin: 20px;
}
.infobox-icon {
  width: 30px;
  margin: 10px;
}

.infobox-title {
  margin: 10px;
  font-size: 18px;
  font-weight: 600;
}

.sub-heading {
  font-size: 30px;
  padding-bottom: 25px;
}

@media only screen and (max-width: 870px) {
  .infoboxes {
    flex-direction: column;
    align-items: center;
  }

  .infoxbox {
    width: fit-content;
  }
}
