.productmenu-container {
  display: flex;
  justify-content: center;
  width: fit-content;
  padding: 200px 0px 0px;
  background-color: #e8dfd8;
}

.product-title {
  text-transform: uppercase;
  font-size: 35px;
  display: flex;
  align-items: center;
  letter-spacing: 10px;
}

.productmenu-container ul {
  align-items: center;
}

.product-section-container {
  padding: 25px 200px 25px 30px;
  margin: 200px 0px 0px;
}

.productmenu-container li {
  background-color: #e8dfd8;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  margin: 0px 15px;
}

.product-button {
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  cursor: pointer;
  transition: 0.2s;
  padding: 10px;
  white-space: nowrap;
}

.product-button:hover {
  color: #f8b912;
}

.productpage-top-image {
  position: relative;
  height: 400px;
  object-fit: cover;
}

.product-images-container {
  display: grid;
  column-gap: 50px;
  grid-template-columns: auto auto auto auto;
}

.product-page-image {
  object-fit: cover;
  width: 300px;
  height: 300px;
}

.product-info-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.product-info-carousel-wrapper {
  width: 50%;
  height: 590px;
}
.product-info-carousel-container {
  margin: 35px;
}

.product-info-text {
  width: 50%;
  margin: 30px 0px;
  font-size: 14px;
  line-height: 20px;
}

.productContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.product-header {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 50%;
  margin-top: 100px;
  left: 50%;
  color: white;
  transform: translate(-50%, -50%);
  flex-direction: row;
}

.product-social-media-icons {
  display: flex;
  align-items: center;
  padding: 10px;
}

.product-social-media-icons a {
  margin: 0px 25px;
}

.product-title-image {
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 200px;
  filter: brightness(0.7);
}

.product-page-setup {
  display: flex;
  flex-direction: column;
}

.product-page-title-container {
  position: relative;
}

@media only screen and (max-width: 1260px) {
  .product-section-container {
    padding: 25px 50px 25px 50px;
    flex-direction: row;
  }
}

@media only screen and (max-width: 870px) {
  .productContainer {
    flex-direction: column;
  }

  .product-section-container {
    padding: 25px;
  }

  .product-info-container {
    flex-direction: column;
  }

  .product-info-carousel-wrapper {
    width: 100%;
  }

  .product-info-text {
    width: 100%;
  }

  .productmenu-container {
    padding: 0px;
    width: 100%;
  }

  .product-dropdown {
    width: 100%;
    background-color: #e8dfd8;
    height: 50px;
    font-size: 14px;
  }

  .product-title {
    font-size: 14px;
    letter-spacing: 5px;
  }
}
