.section-one-container {
  display: flex;
  flex-direction: row;
  padding: 25px 200px 70px 200px;
}

.contact-image-container {
  display: flex;
  width: 50%;
  justify-content: flex-end;
}

.contact-text-container {
  text-align: start;
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px 40px 20px 0px;
}

.contactcard-container {
  background-color: #faf3ed;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.contactcard {
  margin: 50px;
  display: flex;
  flex-direction: row;
}

.contactcard-text {
  margin: 15px;
  line-height: 20px;
}

.mobile-image {
  display: none;
}

@media only screen and (max-width: 870px) {
  .mobile-image {
    display: flex;
    justify-content: center;
  }
  .section-one-container {
    flex-direction: column;
    padding: 25px;
  }

  .contact-image-container {
    display: none;
  }

  .contact-text-container {
    width: 100%;
    padding: 0px 0px 20px 0px;
  }

  .contactcard-container {
    flex-direction: column;
  }

  .contactcard {
    flex-direction: column;
    margin: 25px 0px;
    align-items: center;
  }

  .contactcard-text {
    margin: 10px;
    text-align: center;
  }
}
