.image-gallery {
  position: relative;
  height: 500px; /* Set the height of the gallery */
  overflow: hidden;
}

.image-gallery-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.image-gallery-image.active {
  opacity: 1;
}
