.top-section-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.top-section-image {
  background-image: url(../assets/images/aloe.jpg);
  background-position: center right;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}
.textbox {
  opacity: 0;
  animation: fade-in 2s ease-in-out forwards;
  position: absolute;
  left: 25%;
  top: 50%;
  inline-size: 500px;
  transform: translate(-50%, -50%);
  font-size: 16px;
  line-height: 20px;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.section-container {
  padding: 25px 200px 25px 200px;
  margin: 20px 0px;
}

.section-wrapper-aboutUs {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.section-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.section-wrapper-reverse {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}

.section-image {
  width: 400px;
  height: 400px;
  object-fit: cover;
}

.section-text-wrapper {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section-text-wrapper li {
  line-height: 25px;
  list-style: disc;
  list-style-position: outside;
  margin-bottom: 5px;
  font-size: 14px;
}

.section-image-container {
  display: flex;
  justify-content: flex-end;
  width: 50%;
}

.section-image-reverse-container {
  display: flex;
  justify-content: flex-start;
  width: 50%;
}

.section-text {
  text-align: center;
  background-color: antiquewhite;
  padding: 20px 200px;
  line-height: 20px;
  margin-bottom: 5px;
  font-size: 14px;
}

.sub-heading-text {
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 20px;
}

.aboutUs-social-media-icons {
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
}

.awards-container {
  display: flex;
  flex-direction: row;
  margin: 15px;
}

.soel-crew-images {
  width: 100%;
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

@media only screen and (max-width: 1260px) {
  .section-container {
    padding: 25px 50px 25px 50px;
  }
}
@media only screen and (max-width: 870px) {
  .textbox {
    inline-size: 90%;
    font-size: 14px;
    line-height: 20px;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -30%);
  }

  .soel-crew-images {
    flex-direction: column;
  }

  .top-section-image {
    min-height: 75vh;
  }

  .top-section-container {
    height: 75vh;
  }
  .section-container {
    padding: 25px;
    margin: 20px 0px;
  }

  .section-image {
    width: 100%;
    height: auto;
  }

  .section-text-wrapper {
    width: 100%;
  }

  .section-text {
    padding: 10px;
  }

  .section-image-container {
    display: none;
  }

  .section-image-reverse-container {
    display: none;
  }

  .sub-heading-text {
    font-size: 14px;
    line-height: 20px;
  }

  .sub-heading {
    line-height: 25px;
  }

  .aboutUs-social-media-icons {
    margin: 20px 0px;
  }

  .awards-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px;
  }
}
