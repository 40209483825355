.navbar-container {
  position: fixed;
  width: 100%;
  z-index: 1;
  background-color: #e8dfd8;
  box-shadow: 3px 0px 6px #888888;
  height: 80px;
}

.logo {
  display: block;
}

.header_links li a {
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  color: #070603;
  white-space: nowrap;
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out forwards;
}

.logo-container {
  display: flex;
  justify-content: center;
}

.header_links {
  text-transform: uppercase;
  font-size: 16px;
  margin: 8px 200px;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  color: #070603;
  animation: stagger 1s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes stagger {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media only screen and (max-width: 870px) {
  .navbar-container {
    align-items: center;
    height: 80px;
    display: flex;
  }
}
