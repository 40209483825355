* {
  font-family: "Montserrat", Sans-serif;
  letter-spacing: 1.2px;
  margin: 0;
  padding: 0;
}

li {
  display: list-item;
  list-style-type: none;
}

.mainPage {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.page-container {
  flex: 1;
  padding-top: 80px;
}
